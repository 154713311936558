import { SinglePageFooter } from 'components/organisms/Footer/SinglePageFooter';
import { LoginForm } from 'components/organisms/Forms/LoginForm';
import { SinglePageTemplate } from 'components/templates/SinglePageTemplate';
import { NextSeo } from 'next-seo';

export default function Login() {
	return (
		<SinglePageTemplate isUseBgImage>
			<NextSeo title='ログイン' description='' />
			<LoginForm />
			<SinglePageFooter />
		</SinglePageTemplate>
	);
}
