import { AxiosError } from 'axios';
import { isAxiosError } from 'components/organisms/AxiosClientProvider';
import { INITIAL_USER_STATE } from 'globalStates/atoms/user';
import { useInputText } from 'hooks/inputs/useInputText';
import { useUserState } from 'hooks/state/useUserState';
import { signin } from 'lib/api/users/signin';
import { useRouter } from 'next/router';
import { FormEvent } from 'react';
import { toast } from 'react-toastify';

export function useSigninForm() {
	const router = useRouter();
	const idInput = useInputText('', 'ID', true);
	const passwordInput = useInputText('', 'パスワード', false);
	const [, setUser] = useUserState();

	async function onSubmit(e: FormEvent) {
		e.preventDefault();
		try {
			const response = await signin({
				id: idInput.value,
				password: passwordInput.value,
			});
			setUser(response);
			router.push('/');
		} catch (error) {
			const err = error as AxiosError<{
				code: number;
				message: string;
				detail: string;
			}>;
			if (!isAxiosError(error) || !err.response?.data) {
				toast.error('不明なエラーが発生しました。お問い合わせください');
				return;
			}
			const { code, message, detail } = err.response?.data;
			if (err.response?.data.code === 403003) {
				setUser({
					...INITIAL_USER_STATE,
					is_first_login: true,
					token: 'first_login',
				});
				router.push('/');
				return;
			}
			toast.error(`code: ${code} ${message} ${detail}`);
			toast.error('ログインに失敗しました。ID、パスワードをご確認ください。');
		}
	}

	return { idInput, passwordInput, onSubmit };
}
