import { LoginFormPresenter } from './presenter';
import { LoginFormType } from './types';
import { useSigninForm } from 'hooks/forms/useSinginForm';

export function LoginForm({}: LoginFormType) {
	const { idInput, passwordInput, onSubmit } = useSigninForm();

	return (
		<LoginFormPresenter
			idInput={idInput}
			passwordInput={passwordInput}
			onSubmit={onSubmit}
		/>
	);
}
