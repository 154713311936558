import { SinglePageInputTextType } from './types';
import { DefaultInputText } from '../Default';
import { singlePageInputTextLabel } from './styles';

export function SinglePageInputText<T extends string | number>({
	label,
	labelClassName,
	...props
}: SinglePageInputTextType<T>) {
	return (
		<div className='text-left'>
			<label
				css={singlePageInputTextLabel}
				className={`${labelClassName || ''} block mb-[0.5rem]`}
			>
				{label}
			</label>
			<DefaultInputText {...props} />
		</div>
	);
}
