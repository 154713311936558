import { LoginFormPresneterType } from './types';
import { PrimaryButton } from 'components/molecules/Buttons/Primary';
import {
	loginFormH1,
	loginFormH2,
	loginFormLink,
	loginFormSubmitButton,
	loginFormWrapper,
} from './styles';
import { SinglePageInputText } from 'components/molecules/InputTexts/SinglePageInputText';
import Link from 'next/link';

export function LoginFormPresenter({
	idInput,
	passwordInput,
	onSubmit,
}: LoginFormPresneterType) {
	return (
		<div css={loginFormWrapper} className='text-center rounded-[10px]'>
			<h1
				css={loginFormH1}
				className='inline-flex items-center font-bold before:mr-[5px]'
			>
				花市場Web販売システム
			</h1>
			<h2 css={loginFormH2} className='font-bold'>
				ログイン
			</h2>
			<form onSubmit={onSubmit}>
				<SinglePageInputText
					className='mb-[20px]'
					label={idInput.placeholder || ''}
					value={idInput.value}
					autoComplete='email'
					type='text'
					required={idInput.required}
					onChange={idInput.onChange}
				/>
				<SinglePageInputText
					className='mb-[30px]'
					label={passwordInput.placeholder || ''}
					value={passwordInput.value}
					autoComplete='password'
					type='password'
					required={passwordInput.required}
					onChange={passwordInput.onChange}
				/>
				<PrimaryButton css={loginFormSubmitButton} type='submit'>
					ログイン
				</PrimaryButton>
				<div>
					<Link
						css={loginFormLink}
						className='underline'
						href='/reset-password'
					>
						パスワードをお忘れの方
					</Link>
				</div>
			</form>
		</div>
	);
}
