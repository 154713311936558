import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const loginFormWrapper = (theme: Theme) => css`
	background-color: rgba(255, 255, 255, 0.8);
	width: ${theme.users.width.desktop.singlePageInnerBox};
	padding-top: ${theme.users.spacing.desktop.singlePageInnerBoxInnerVertical};
	padding-bottom: ${theme.users.spacing.desktop
		.singlePageInnerBoxInnerVertical};
	padding-left: ${theme.users.spacing.desktop
		.singlePageInnerBoxInnerHorizontal};
	padding-right: ${theme.users.spacing.desktop
		.singlePageInnerBoxInnerHorizontal};
	margin-bottom: ${theme.users.spacing.desktop.singlePageInner};
	${maxScreen('mobile')} {
		width: 100%;
		padding: ${theme.users.spacing.mobile.singlePageInnerBoxInnerVertical} 7%;
		margin-bottom: 6vw;
	}
`;

export const loginFormH1 = (theme: Theme) => css`
	color: ${theme.users.colors.primary};
	font-family: 'M PLUS Rounded 1c', sans-serif;
	font-size: 1.875rem;
	line-height: 1;
	margin-bottom: ${theme.users.spacing.desktop.singlePageH1Bottom};
	&:before {
		content: '';
		display: block;
		width: 55px;
		height: 2.25rem;
		background-image: url(../images/common/icon_ttl_site.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
	${maxScreen('mobile')} {
		font-size: 1.4rem;
		&:before {
			content: '';
			display: block;
			width: 33px;
			height: 1.4rem;
			background-image: url(../images/common/icon_ttl_site.svg);
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}
`;

export const loginFormH2 = (theme: Theme) => css`
	line-height: 1;
	font-size: 1.4rem;
	margin-bottom: ${theme.users.spacing.desktop.singlePageH2Bottom};
	${maxScreen('mobile')} {
		font-size: 5vw;
		margin-bottom: ${theme.users.spacing.mobile.singlePageH2Bottom};
	}
`;

export const loginFormLink = (theme: Theme) => css`
	color: ${theme.users.colors.link.default};
	line-height: 1;
`;

export const loginFormSubmitButton = (theme: Theme) => css`
	margin-bottom: 32px;
	${maxScreen('mobile')} {
		margin-bottom: 20px;
	}
`;
