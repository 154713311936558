import Link from 'next/link';
import { singlePageFooterText } from './styles';

export function SinglePageFooter() {
	return (
		<div className='mb-[30px]'>
			<Link href='https://yama-f-market.com/' css={singlePageFooterText}>
				© 山口県中央花市場 Co,. Ltd.
			</Link>
		</div>
	);
}
