import { useTheme } from '@emotion/react';
import { singlePageTemplateWrapper } from './styles';
import { SinglePageTemplateType } from './types';

export function SinglePageTemplate({
	children,
	isUseBgImage = false,
}: SinglePageTemplateType) {
	const theme = useTheme();

	return (
		<div
			css={singlePageTemplateWrapper(theme, isUseBgImage)}
			className='min-h-full w-full flex flex-col justify-start items-center'
		>
			{children}
		</div>
	);
}
