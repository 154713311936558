import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const singlePageTemplateWrapper = (
	theme: Theme,
	isUseBgImage?: boolean,
) => css`
	${isUseBgImage
		? `background-image: url("images/login_bg_pc.jpg");`
		: `background-color: ${theme.users.colors.background.primary};`}
	background-size: cover;
	padding: ${theme.users.spacing.desktop.singlePageInner} 0 0;
	min-height: 100vh;
	${maxScreen('mobile')} {
		padding: 6vw 4vw;
		${isUseBgImage
			? `background-image: url("images/login_bg_sp.jpg");`
			: `background-color: ${theme.users.colors.background.primary};`}
	}
`;
